import { graphql } from 'gatsby';
import React from 'react';
import SingleTemplate from '~/templates/single';
import { getIndexPageMeta } from '../utils/helpers';
import DefaultProxy from '../components/proxy/blocks';
import { PAGE_TYPE } from '../utils/constants';

const NotFoundPage = (props) => {
  const seo = getIndexPageMeta({
    pathname: props.location.pathname,
    siteMetadata: props.data.site.siteMetadata,
    frontmatter: props.data.markdownRemark.frontmatter,
  });

  const propsWithPageContext = {
    ...props,
    pageContext: {
      slug: '/404-page-not-found/',
    },
  };

  return (
    <SingleTemplate
      seo={seo}
      pageType={PAGE_TYPE.NotFound}
      proxy={DefaultProxy}
      {...propsWithPageContext}
    />
  );
};


export const notFoundPageQuery = graphql`
  query NotFoundPageQuery {
    site {
      siteMetadata {
        siteName
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: "/404-page-not-found/" } }) {
      ...PageMarkdownFragment
    }
    ...ConfigFragment
  }
`;

export default NotFoundPage;
